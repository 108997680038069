<template>
	<div class=''>
		<div class="h76 bg-f9 pl12 pr12 pt16 flex row-center">
			<!-- 左 -->
			<!-- 			<div>
				<div class="flex col-center">
					<img src="@/assets/images/news/sina.png" class="w20 h16 mr12">
					<img src="@/assets/images/news/wx.png" class="w20 h16 mr12">
					<img src="@/assets/images/news/zone.png" class="w20 h16 mr12">
					<div class="flex">
						<div class="w4 h4 radius4 mr4" style="background: #979797;" v-for="i in 3" :key="i"></div>
					</div>
				</div>

				<div class="fs11 color9 mt12">
					2022-08-30
				</div>
			</div> -->
			<!-- 右 -->
			<div class="fs11 color9">
				<div class="flex">
					<div class="w50 title">来源</div>:{{newsD.source|sourceFn}}
				</div>
				<div class="flex mt5 mb5">
					<div class="w50 title">发布时间</div>:{{newsD.releaseTime}}
				</div>
				<div class="flex">
					<div class="w50 title ">浏览量</div>:{{newsD.views}}
				</div>
			</div>
		</div>

		<!-- 内容 -->
		<div class="pl16 pr16 pt20">
			<div class="text-center fs16 fw-600">{{newsD.contentName}}</div>
			<div class="fs11 color9D ql-editor" v-html="newsD.content"></div>
		</div>
		<!-- //内容 -->

		<div class="mt37 bg-f9 pt10 pb10 pr10 pl10 bg-f9 flex pl12 pr12" @click="btnNews(nextNewsD.id)"
			v-if="nextNewsD.contentName">
			<img :src="nextNewsD.wapPic" class="w163 h105 mr8">
			<div class="flex row-column row-between">
				<div class="fs16 fw-600">{{nextNewsD.contentName}}</div>
				<div class="fs11 color9D">{{nextNewsD.newsIntro}}</div>
				<div class="fs11 color9D">{{nextNewsD.releaseTime}}</div>
			</div>
		</div>


		<div class="pl12 pr12 pb30">
			<van-button type="default" @click="update(newsD.upId)">上一篇</van-button>
			<van-button type="default" class="mr10 ml10" @click="update(newsD.downId)">下一篇</van-button>
			<van-button type="info" @click="$router.back()">返回列表</van-button>
		</div>
	</div>
</template>

<script>
	import { getNewsDetail } from '@/api'
	export default {
		data() {
			return {
				id: null,
				nextId: null,
				newsD: {},
				nextNewsD: {}
			};
		},
		filters: {
			sourceFn(val) {
				return val == 0 ? '铭智生科' : '转载'
			}
		},
		components: {},

		created() {
			this.id = this.$route.params.id
			this.getNewsDetail()
		},
		mounted() {},

		methods: {
			//获取新闻详情
			async getNewsDetail() {
				const res = await getNewsDetail({ id: this.id })
				res.data.content = res.data.content.replace(/<img /g, '<img style="width:100%" ')
				this.newsD = res.data
				this.nextId = res.data.downId
				if (this.nextId != 0) {
					this.getNextD(this.nextId)
				} else {
					this.nextNewsD = {}
				}
			},
			//获取下一篇新闻详情
			async getNextD(id) {
				const res = await getNewsDetail({ id })
				this.nextNewsD = res.data
			},
			btnNews(id) {
				this.id = id
				this.getNewsDetail()
			},
			//上一篇下一篇
			update(id) {
				if (id == 0) {
					this.$toast.fail('没有文章了');
				} else {
					document.documentElement.scrollTop = 0;
					this.id = id
					this.getNewsDetail()
				}
			},
		}
	}
</script>
<style lang='scss' scoped>
	img {
		display: inline-block !important;
	}

	.title {
		text-align: justify;
		text-align-last: justify;
	}

	::v-deep .van-button {
		padding: 0;
		font-size: 12px;
		font-weight: 500;
		color: #272727;
		width: 127px;
		height: 28px;
		border: 1px solid #272727;
	}

	::v-deep .van-button--info {
		width: 77px;
		height: 28px;
		background: #272727;
		border: 1px solid #272727;
		color: #FFFFFF;
	}
</style>
